import React, { FC } from 'react';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { mobile, tablet, useQuery } from 'styles/breakpoints';

import Text from './Text';
import Full from 'assets/icons/upgrade/stars.svg';
import 'swiper/css';
import 'swiper/css/pagination';
import { DynamicImage } from './images/DynamicImage';
import ReviewsStarsRow from './reviews/ReviewsStarsRow';
import theme from 'utils/theme';

type reviewItem = {
  image?: string;
  name?: string;
  text?: string;
};
interface ReviewsProps {
  title: string;
  reviews: reviewItem[];
  starImgUrl?: string;
  starImgUrlAlt: string;
  onClick?: () => void;
  mobileTitleAlt?: string;
  bgColor?: string;
}

const Container = styled.div`
  padding: 4rem 1rem 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const InnerWrapper = styled.div`
  max-width: 71.25rem;
  width: 100%;
`;

const Title = styled(Text)`
  max-width: 48rem;
  width: 100%;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.375rem;
  text-align: center;
  margin: 0 auto 3rem;
  @media ${tablet} {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }
`;

const SwiperStyled = styled(Swiper)`
  max-width: 67.33rem;
  width: 100%;
  .swiper-slide {
    margin-bottom: 2.5rem;
    max-width: 21.4375rem;
    width: 100%;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }

  .swiper-pagination {
    bottom: 0;
    width: 100%;
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: rgba(115, 137, 156, 0.5);
    &-active {
      background-color: #804da1;
      width: 8px;
    }
  }

  .swiper-wrapper {
    margin-bottom: 3.375rem;
  }

  @media ${tablet} {
    margin-top: 1.5rem;

    .swiper-wrapper {
      margin-bottom: 0;
    }
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0.75rem;
  border: 1px solid #f3f3f8;
  background: #fbfbff;
  overflow: hidden;
  max-width: 21.4375rem;
  width: 100%;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0.75rem 1rem;
`;

const Reviews: FC<ReviewsProps> = ({ title, reviews }) => {
  const { isTablet } = useQuery();
  return (
    <Container>
      <InnerWrapper>
        <Title>{title}</Title>

        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove
          effect="slide"
          slidesPerView={1}
          spaceBetween={20}
          breakpoints={{
            480: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
          }}
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index}>
              <CardContainer>
                {review.image && <Img src={review.image} alt="Slide" />}

                <RowWrapper>
                  <Name>{review.name}</Name>
                  <Full />
                </RowWrapper>

                <Review>{review.text}</Review>
              </CardContainer>
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </InnerWrapper>
    </Container>
  );
};

export default Reviews;

const Review = styled(Text)`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 0 0.75rem 0.75rem;
`;

const Name = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
`;

const Img = styled(DynamicImage)`
  width: 100%;
`;
