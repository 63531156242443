import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { Text } from 'components';
import ReactCountdown from 'react-countdown';

interface HeaderCountdownProps {
  isPaymentComponentVisible: boolean;
  offerText?: string;
  discountText?: string;
}

export const HeaderCountdown: FC<HeaderCountdownProps> = ({
  isPaymentComponentVisible,
  offerText,
  discountText,
}) => {
  const countdownDate = useMemo(() => Date.now() + 900000, []);
  const { isMobile } = useQuery();
  const bookSavings = window.location.pathname.includes('checkout-special')
    ? '80'
    : '70';
  const renderer = ({
    formatted = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    },
  }) => (
    <ReactCountdownContainer>
      <CountdownUnitsContainer>
        <Time isWhite={isPaymentComponentVisible}>{formatted.minutes}</Time>
      </CountdownUnitsContainer>
      <Colon isWhite={isPaymentComponentVisible}>:</Colon>
      <CountdownUnitsContainer>
        <Time isWhite={isPaymentComponentVisible}>{formatted.seconds}</Time>
      </CountdownUnitsContainer>
    </ReactCountdownContainer>
  );

  return (
    <HeaderCountDown isPaymentComponentVisible={isPaymentComponentVisible}>
      {isPaymentComponentVisible ? (
        <CountdownTitlePayment>
          {offerText ?? 'The offer expires in:'}
        </CountdownTitlePayment>
      ) : (
        <CountdownTitle>
          {bookSavings}% {discountText ?? ' discount reserved for'}:
        </CountdownTitle>
      )}
      <ReactCountdown date={countdownDate} renderer={renderer} />
    </HeaderCountDown>
  );
};

const CountdownTitlePayment = styled.p`
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const HeaderCountDown = styled.div<{ isPaymentComponentVisible: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ isPaymentComponentVisible }) =>
    isPaymentComponentVisible && 'max-width: 44.4rem; margin: 0 auto;'}
`;

const ReactCountdownContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CountdownUnitsContainer = styled.div<{ hasMarginLeft?: boolean }>`
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: ${({ hasMarginLeft }) => (hasMarginLeft ? '0.625rem' : '0')};
`;

const Time = styled(Text)<{ isWhite?: boolean }>`
  color: ${({ isWhite }) => (isWhite ? '#fff' : '#000')};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

const Colon = styled(Time)`
  margin: 0 3px;
`;

const CountdownTitle = styled.p`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;
