import React, { FC, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Question, QuizAnswer, Quiz as QuizType } from 'types/quiz';
import { DynamicImage, Text } from 'components';
import { mMobile, mobile, tablet, useQuery } from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import LCFooter from 'components/LCFooter';
import socProof from '../../../../assets/images/lastingChange/soc-prof-mob.png';
import socProof_des from '../../../../assets/images/lastingChange/soc-prof-des.png';
import bfSocProof from '../../../../assets/images/lastingChange/bf-soc-prof-mob.png';
import bfSocProof_des from '../../../../assets/images/lastingChange/bf-soc-prof-des.png';
import xmasSocProof from '../../../../assets/images/lastingChange/xmas-soc-prof-mob.png';
import xmasSocProof_des from '../../../../assets/images/lastingChange/xmas-soc-prof-des.png';
import nySocProof from '../../../../assets/images/lastingChange/photo-mobile-NY.png';
import nySocProof_des from '../../../../assets/images/lastingChange/photo-desktop-NY.png';
import winterSocProof from '../../../../assets/images/lastingChange/photo-mobile-Christmas.png';
import winterSocProof_des from '../../../../assets/images/lastingChange/photo-desktop-christmas.png';
import valentineSocProof from '../../../../assets/images/lastingChange/valentine-soc-prof-mob.png';
import valentineSocProof_des from '../../../../assets/images/lastingChange/valentine-soc-prof-des.png';
import PersonalisedIcons from './PersonalisedIcons';
import BlackFridayLandingLabel from './BlackFridayLandingLabel';
import { useScrollToTop } from 'utils/hooks';
import PRFooter from 'components/PRFooter';
import Header from 'components/reviews/Header';
import { useLocation } from '@reach/router';
import LoopingBanner from 'components/LoopingBanner';
import { PriorityHigh } from '@material-ui/icons';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
}

interface ButtonsContainerProps {
  length: number;
  isBfVariant?: boolean;
}

const Landing: FC<LandingProps> = ({ question, onOptionSelect }) => {
  const { isMobile } = useQuery();
  const location = useLocation();
  const quiz = new URLSearchParams(location.search).get('qz') ?? QuizType.Main;
  const isAddsFlow =
    location.pathname.includes('start') || quiz !== QuizType.Main;
  const pickSocialProfImage = (variant?: string) => {
    if (variant === 'blackFriday') {
      return {
        mob: bfSocProof,
        des: bfSocProof_des,
      };
    }
    if (variant === 'xmas') {
      return {
        mob: xmasSocProof,
        des: xmasSocProof_des,
      };
    }
    if (variant === 'ny') {
      return {
        mob: nySocProof,
        des: nySocProof_des,
      };
    }
    if (variant === 'valentine') {
      return {
        mob: valentineSocProof,
        des: valentineSocProof_des,
      };
    }
    if (variant === 'winter') {
      return {
        mob: winterSocProof,
        des: winterSocProof_des,
      };
    }
    return {
      mob: socProof,
      des: socProof_des,
    };
  };

  const renderGenderButtons = (options: any[], isDoubleButton?: boolean) => (
    <>
      {options?.map((option, index) => (
        <GenderButton
          key={index}
          color={option?.color}
          onClick={() =>
            onOptionSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          marginRight={index === 0}
          isSingleButton={question?.options?.length === 1}
          isDoubleButton={isDoubleButton}
        >
          {option?.label}
        </GenderButton>
      ))}
    </>
  );

  const renderGenderBasic = (options: any[]) => {
    if (!options.length) return null;
    if (isMobile && question?.bfLabel) return null;
    return options?.map((option, index) => {
      const hasColor = false;
      return (
        <OtherGenderButton
          onClick={() =>
            onOptionSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          colorActive={option?.color}
          key={index}
        >
          <OtherButtonText>{option?.label}</OtherButtonText>
        </OtherGenderButton>
      );
    });
  };

  const renderGenderOptions = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsContainer
        length={[...primaryOptions, ...secondaryOptions].length}
        isBfVariant={question?.bannerTexts ? true : false}
      >
        {renderGenderButtons(primaryOptions, secondaryOptions.length === 0)}
        {renderGenderBasic(secondaryOptions)}
      </ButtonsContainer>
    );
  };

  useScrollToTop(0);
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  const { isTablet } = useQuery();

  return (
    <>
      <Container>
        {!isAddsFlow && (
          <StickyHeaderContainer>
            <Header isMobileApp={false} isNotSticky={!!question?.bannerTexts} />
            {question?.bannerTexts && (
              <LoopingBanner
                bannerTexts={question?.bannerTexts.texts}
                primaryTextColor={question?.bannerTexts.primaryColor}
                secondaryTextColor={question?.bannerTexts.secondaryColor}
                primaryGradient={question?.bannerTexts.primaryGradient}
                secondaryGradient={question?.bannerTexts.secondaryGradient}
                backgroundColor={question?.bannerTexts.backgroundColor}
                isNotSticky={true}
              />
            )}
          </StickyHeaderContainer>
        )}
        {isAddsFlow && question?.bannerTexts && isTablet && (
          <>
            <BFHeaderContainer>
              <BFHeaderWrapper>
                <HeaderImg src="lastingChange/LastingChange_mob.png" alt="" />
              </BFHeaderWrapper>
            </BFHeaderContainer>
            <LoopingBanner
              bannerTexts={question?.bannerTexts.texts}
              primaryTextColor={question?.bannerTexts.primaryColor}
              secondaryTextColor={question?.bannerTexts.secondaryColor}
              primaryGradient={question?.bannerTexts.primaryGradient}
              secondaryGradient={question?.bannerTexts.secondaryGradient}
              backgroundColor={question?.bannerTexts.backgroundColor}
              isNotSticky={false}
            />
          </>
        )}
        {isAddsFlow && question?.bannerTexts && !isTablet && (
          <>
            <LoopingBanner
              bannerTexts={question?.bannerTexts.texts}
              primaryTextColor={question?.bannerTexts.primaryColor}
              secondaryTextColor={question?.bannerTexts.secondaryColor}
              primaryGradient={question?.bannerTexts.primaryGradient}
              secondaryGradient={question?.bannerTexts.secondaryGradient}
              backgroundColor={question?.bannerTexts.backgroundColor}
              isNotSticky={false}
            />
          </>
        )}

        <Wrapper isFullHeight={isAddsFlow}>
          {isAddsFlow && !question?.bannerTexts && (
            <HeaderContainer>
              <HeaderImg src="lastingChange/LastingChange_mob.png" alt="" />
            </HeaderContainer>
          )}

          <W1>
            <SocialProof
              quizParam={quiz}
              mob={pickSocialProfImage(question?.socialProofVariant).mob}
              des={pickSocialProfImage(question?.socialProofVariant).des}
              isFullHeight={true}
              isBanner={!!question?.bannerTexts}
            />
          </W1>

          <WrappedItem>
            <W3>
              {isAddsFlow && question?.bannerTexts && !isTablet && (
                <HeaderContainer>
                  <HeaderImg src="lastingChange/LastingChange_mob.png" alt="" />
                </HeaderContainer>
              )}

              <Title dangerouslySetInnerHTML={{ __html: question?.title }} />

              <DescriptionContainer>
                <Label
                  dangerouslySetInnerHTML={{
                    __html: question?.description || '',
                  }}
                />
              </DescriptionContainer>
              <ButtonLabel
                dangerouslySetInnerHTML={{ __html: question?.label }}
              />

              {renderGenderOptions()}
              {question?.bfLabel && (
                <BlackFridayLandingLabel {...question.bfLabel} />
              )}
            </W3>
          </WrappedItem>
        </Wrapper>
        {!isAddsFlow ? (
          <LCFooter disclaimerText={question?.disclaimerParagraphs || []} />
        ) : (
          <PRFooter disclaimerText={question?.disclaimerParagraphsPR || []} />
        )}
      </Container>
    </>
  );
};

export default Landing;

const OtherButtonText = styled.p`
  color: #000;
`;

const W3 = styled.div`
  max-width: 720px;
  width: 100%;
  align-self: center;

  @media ${tablet} {
    margin: auto;
  }
`;

const W1 = styled.div`
  display: flex;
  flex: 1;
  max-width: 50%;
  height: 100%;

  @media ${tablet} {
    max-width: 100%;
    flex: 0;
    padding: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const WrappedItem = styled.div`
  display: flex;
  flex: 1;
  @media ${tablet} {
    padding: 0 1rem;
    width: unset;
    margin-left: unset;
    flex: 0;
  }
`;

const Wrapper = styled.div<{ isFullHeight?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  flex: 1;
  gap: 3rem;
  max-width: 90rem;
  width: 100%;
  height: 100vh;
  padding: 0 3rem;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.light0};
  ${({ isFullHeight }) => isFullHeight && 'min-height: 100vh;'}
  @media ${tablet} {
    padding: 0;
    flex-direction: column;
    align-items: unset;
    gap: 0;
  }
`;

const HeaderImg = styled(DynamicImage)`
  width: 9.5rem;
  height: 1.25rem;
`;

const HeaderContainer = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  height: 4.25rem;
  left: 3rem;
  margin: 0 auto;
  width: 100%;

  @media ${tablet} {
    position: static;
    align-items: center;
    justify-content: center;
  }
`;

const BFHeaderContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 8;

  @media ${tablet} {
    position: relative;
  }
`;

const BFHeaderWrapper = styled.div`
  max-width: 90rem;
  width: 100%;
  padding: 0 3rem;
  padding: 1.125rem 0;

  @media ${tablet} {
    display: flex;
    justify-content: center;
    padding: 1.25rem 0;
  }
`;

const slideV = keyframes`
  from {
    background-position: 0 0;

  }

  to {
    background-position: 0 10000%;

  }
`;

const slideH = keyframes`
  from {
    background-position: 0 0;

  }

  to {
    background-position: 10000% 0;

  }
`;

const SocialProof = styled.div<{
  quizParam: string;
  des: string;
  mob: string;
  isFullHeight?: boolean;
  isBanner?: boolean;
}>`
  position: relative;
  background-size: cover;
  animation: ${slideV} 1500s linear infinite;
  width: 100%;
  background-image: url(${({ des }) => des});
  height: ${({ isFullHeight }) => (isFullHeight ? '100vh' : '85vh')};
  @media ${tablet} {
    animation: ${slideH} 1500s linear infinite;
    background-image: url(${({ mob }) => mob});
    ${({ isBanner }) => isBanner && 'margin-top: 0.5rem'};
    height: 308px;
  }

  @media ${mMobile} {
    ${({ isBanner }) => isBanner && 'height: 180px;'}
  }
`;

const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  ${({ isBfVariant }) => !isBfVariant && 'flex-wrap: wrap;'}
  ${({ isBfVariant }) => isBfVariant && 'gap: 1rem;'}

  @media ${tablet} {
    padding-bottom: 32px;
    justify-content: center;
    ${({ isBfVariant }) => isBfVariant && 'gap: 0.5rem;'}
    ${({ length }) => length > 2 && 'flex-wrap: wrap;'}
  }
`;

const Title = styled(Text)`
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 4rem;
  max-width: 547px;
  text-align: left;
  display: flex;
  width: 100%;

  padding-bottom: 1.5rem;
  @media ${tablet} {
    padding-bottom: 1rem;
    justify-content: center;
    text-align: center;
    padding: 1.5rem 0 1rem;
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

const Label = styled(Text)`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  @media ${tablet} {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }
`;

const ButtonLabel = styled(Text)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  padding-bottom: 1.5rem;
  @media ${tablet} {
    text-align: center;
    padding-bottom: 0.75rem;
    display: flex;
    justify-content: center;
  }
`;

const DescriptionContainer = styled.div`
  padding-bottom: 3rem;

  @media ${tablet} {
    text-align: center;
    text-align-last: center;
    padding-bottom: 1.5rem;
    display: flex;
    justify-content: center;
  }
`;

const GenderButton = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})<{
  marginRight?: boolean;
  isSingleButton?: boolean;
  isDoubleButton?: boolean;
  color?: string;
}>`
  padding: 0 1rem;
  width: 100%;
  height: 3rem;
  ${({ isDoubleButton }) => !isDoubleButton && 'max-width: 15rem;'}
  ${({ isDoubleButton }) => !isDoubleButton && 'margin-right: 1rem;'}
  flex-basis: ${({ isSingleButton, isDoubleButton }) =>
    isSingleButton
      ? '100%'
      : `calc((100% / ${isDoubleButton ? 2 : 3}) - 1rem)`};

  ${({ isDoubleButton }) => isDoubleButton && 'flex-basis: 100%;'}

  ${({ color }) => color && `background:${color};`};
  @media ${tablet} {
    max-width: 30rem;
    flex-basis: ${({ isSingleButton }) =>
      isSingleButton ? '100%' : 'calc(50% - 1rem)'};
    margin-right: ${({ marginRight }) => (marginRight ? 1 : 0)}rem;
  }
`;

const OtherGenderButton = styled(GenderButton)`
  flex-basis: calc(33% - 1rem);
  color: black;
  background-color: ${({ theme, color }) =>
    color ? theme.colors[color] : 'transparent'};

  @media ${tablet} {
    flex-basis: calc(100% - 1rem);
    margin-top: 0.5rem;
  }
`;

const LooperContainer = styled.div`
  @media ${tablet} {
    margin-bottom: 0.5rem;
  }
`;

const StickyHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
`;
