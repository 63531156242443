import React, {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  useMemo,
} from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import type { Color } from 'utils/theme';
import { CircularProgress } from '@material-ui/core';
import { tablet } from 'styles/breakpoints';

interface PrimaryButtonProps
  extends PropsWithChildren<ComponentPropsWithoutRef<'button'>> {
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
  cornerVariant?: 'default' | 'round';
  minHeight?: number;
  color?: Color;
  colorActive?: Color;
  fontSize?: number;
  fontFamily?: string;
  fontColor?: string;
  loading?: boolean;
}

const Container = styled(ButtonContainer)<{
  active?: boolean;
  disabled?: boolean;
  minHeight?: number;
  color: Color;
  colorActive: Color;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 3rem;
  border-radius: 6.25rem;
  padding: 0.5rem 1.25rem;
  align-items: center;
  justify-content: center;
  background: ${({ active }) => (active ? '#FFB79C' : '#FF875A')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: 0.15s;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  border: none;
  width: 100%;
`;

const ButtonText = styled(Text)`
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
`;

const CircularProgressStyled = styled(CircularProgress)`
  height: 1.25rem;
  width: 1.25rem;
`;

const PrimaryButton: FC<PrimaryButtonProps> = ({
  onClick,
  children,
  active,
  disabled,
  cornerVariant = 'default',
  minHeight,
  color = 'green',
  colorActive = 'greenDark',
  fontSize,
  fontFamily,
  fontColor,
  loading,
  ...props
}) => {
  const borderRadius = useMemo(
    () => (cornerVariant === 'round' ? 10 : 0.5),
    [cornerVariant],
  );

  return (
    <Container
      onClick={onClick}
      as="button"
      borderRadius={borderRadius}
      active={active}
      disabled={disabled}
      minHeight={minHeight}
      color={color}
      colorActive={colorActive}
      {...props}
    >
      {loading ? (
        <CircularProgressStyled style={{ color: '#fff' }} size={20} />
      ) : (
        <ButtonText>{children}</ButtonText>
      )}
    </Container>
  );
};

export default React.memo(PrimaryButton);
